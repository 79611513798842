<template>
    <div class="container_account_confirmation">
        <b-container class="py-5">
            <b-row align-h="center">
                <b-col cols="12" class="text-center mb-5">
                    <router-link :to="{ name: 'home' }">
                        <img src="@/assets/img/api-monitor.png" width="250"/>
                    </router-link>
                </b-col>
                <b-col cols="12" lg="7">
                    <h1 class="text-center fw-bold mb-3">
                        We are almost done!
                    </h1>
                    <h5 class="text-center fw-bold">
                        Please help us to confirm your email address, with the link we send you.
                    </h5>
                    <h2 class="mt-5 mb-4 text-center fw-bold">Did not receive any email?</h2>
                    <div class="text-center">
                        <b-button variant="secondary" class="px-4" @click="resendConfirmation()">Resend email</b-button>
                    </div>
                </b-col>
                <b-col cols="12" class="text-center py-5 foot">
                    <p class="mb-0 fw-bold text-white">
                        &copy; 2022 Apimonitor24 |
                        <a href="#" class="text-decoration-none text-white">apimonitor24.com</a> | Do Genius On, LLC.
                        All rights reserved
                    </p>
                </b-col>
            </b-row>
        </b-container>
        <b-overlay :show="load" opacity="1.00" variant="transparent" blur="2px" no-wrap/>
    </div>
</template>
<script>

export default {
    name: 'RegisterSuccess',
    data() {
        return {
            load: false
        };
    },
    methods: {
        resendConfirmation(){
            this.load = true;
            this.$http.post('resendConfirmationEmail', {email: this.$route.query.email}).then(response => {
                this.load = false;
                if (response.status == 200) {
                    this.$notify({ group: 'notifications_home', type: 'success', text: response.body.message });
                }
            }, error => {
                console.log(error)
                if (error.status == 400) {
                    this.$notify({ group: 'notifications_home', type: 'warning', text: error.body.message });
                }else{
                    this.$notify({ group: 'notifications_home', type: 'warning', text: 'An error has occurred, please try again.' });
                }
                
            })
        },
    },
};
</script>